import Select from 'react-select';

const colourStyles = {
  control: styles => ({ ...styles, width: '150px', cursor: 'pointer', backgroundColor: '#454E69', border: '1px solid #fff', color: '#fff' }),
  menu: (styles => ({ ...styles, backgroundColor: '#454E69', borderRadius: '4px', marginTop: '0' })),
  menuList: (styles => ({ ...styles, backgroundColor: '#454E69', borderRadius: '4px', border: '1px solid #fff', padding: '0' })),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#fff' : '#454E69',
      color: isSelected ? '#454E69' : '#FFF',
      cursor: 'pointer',
      borderBottom: '1px solid #fff'
    };
  },
  singleValue: styles => ({ ...styles,  color: '#fff' }),
  placeholder: styles => ({ ...styles, color: '#fff' })
};

const SelectController = (props) => {
  const { value, handleChange, options, menuIsOpen, placeholder, name } = props;
  return (
    <Select
      value={value ? { value: value, label: value } : false}
      onChange={option => handleChange(option.value, name)}
      options={options}
      menuIsOpen={menuIsOpen}
      placeholder={placeholder}
      styles={colourStyles}
    />
  )
}

export default SelectController;