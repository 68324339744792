import { useState } from 'react';
import SelectController from '../common/SelectController';
import InputController from '../common/InputController';

import styles from './Calculator.module.scss';

const questionOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

const provinceOptions = [
  { value: 'ON', label: 'ON', coefficient: 0.08 },
  { value: 'BC', label: 'BC', coefficient: 0.10 },
  { value: 'AB', label: 'AB', coefficient: 0 },
  { value: 'SK', label: 'SK', coefficient: 0.10 },
  { value: 'MB', label: 'MB', coefficient: 0.15 },
  { value: 'NS', label: 'NS', coefficient: 0.15 },
  { value: 'NB', label: 'NB', coefficient: 0.15 },
  { value: 'NL', label: 'NL', coefficient: 0.15 },
  { value: 'PE', label: 'PE', coefficient: 0 },
  { value: 'YT', label: 'YT', coefficient: 0.15 },
  { value: 'NU', label: 'NU', coefficient: 0 },
  { value: 'NT', label: 'NT', coefficient: 0 },
]

const Calculator = () => {
  const [fieldValues, setValues] = useState({
    firstQuestion: 'Yes',
    secondQuestion: 'No',
    province: 'ON',
    ownersT4Salaries: '',
    employeeT4Salaries: '',
    scrapMaterials: '',
    subContractors: '',
    cewsIRAP: '',
    federalITCCredits: '',
    ontarioOITCCredits: '',
    ontarioORDTCCredits: '',
    priorYearExpenditures: '',
    secondYearExpenditures: '',
    total: 0
  });

  const renderAboutFederalSREDCredits = () => {
    return (
      <div className={styles.about_text}>
        <br />
        <h3>About Federal SRED Credits</h3>
        <p>Federal ITCs are refundable for CCPC’s up to the $3M expenditure limit
        (please note that expenditure limits are not considered in this calculator)
        </p>
        <p>Federal ITCs are non-refundable for CCPC’s above the expenditure limit
          and for public and foreign-controlled corporations</p>
        <br />
      </div>
    )
  }

  const renderAboutProvinceSREDCredits = () => {
    const { province } = fieldValues;

    if (province === 'ON') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Ontario SRED Credits</h3>
          <p>ON OITC – 8% refundable credit for all companies up to the $3M
            expenditure limit
          <br />
          Exp limit is reduced for $500-800K prior year taxable income and
            $25-50M taxable capital</p>
          <p>ON ORDTC – Non-refundable 3.5% credit for all companies</p>
        </div>
      )
    }

    if (province === 'BC') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About British Columbia SRED tax credits</h3>
          <p>The BC SRED tax credit is a 10% refundable credit to CCPCs up to
            the $3M expenditure limit</p>
          <p>The BC SRED tax credit is a 10% non-refundable credit for CCPCs
            above the expenditure limit and for public and foreign-controlled
            corporations</p>
        </div>
      )
    }

    if (province === 'AB') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Alberta IEG SRED Credits</h3>
          <p>AB IEG SRED credits are only valid for qualifying expenditures after January 1, 2021. There were no
            AB provincial SRED credits awarded for any expenditures in 2020
          </p>
          <p>The AB IEG is an 8% refundable credit for all corporations in AB</p>
          <p>There is a bonus 12% IEG awarded on all SRED expenditures over the average of the corporation’s
              prior 2 years SRED expenditures
          </p>
          <p>The IEG is subject to a $4M expenditure limit. This expenditure limit is subject to reduction based on
              taxable capital between $10M and $50M
          </p>
        </div>
      )
    }

    if (province === 'SK') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Saskatchewan R&D tax credits</h3>
          <p>The SK R&D tax credit is a 10% refundable tax credit for CCPC’s up to a $1M expenditure limit</p>
          <p>The SK R&D tax credit is a 10% non-refundable tax credit for CCPC expenditures over $1M and for
            public and foreign-owned corporations</p>
        </div>
      )
    }

    if (province === 'MB') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Manitoba R&D tax credits</h3>
          <p>The MB R&D tax credit is a 7.5% refundable tax credit and a 7.5% non-refundable tax credit for all
            corporations in MB</p>
          <p>There is no expenditure limit</p>
          <p>MB is the only province that includes capital expenditures as valid SRED expenditures. Must be
            depreciable, buildings and leaseholds are not included</p>
        </div>
      )
    }

    if (province === 'NS') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Nova Scotia R&D tax credits</h3>
          <p>The NS R&D tax credit is a 15% refundable tax credit for all corporations in NS</p>
          <p>There is a $3M expenditure limit which mirrors the federal policy</p>
        </div>
      )
    }

    if (province === 'NB') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About New Brunswick R&D tax credits</h3>
          <p>The NB R&D tax credit is a 15% refundable tax credit for all corporations in NB</p>
          <p>There is a $3M expenditure limit which mirrors the federal policy</p>
        </div>
      )
    }

    if (province === 'NL') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Newfoundland and Labrador R&D tax credits</h3>
          <p>The NL R&D tax credit is a 15% refundable tax credit for all corporations in NL</p>
          <p>There is a $3M expenditure limit which mirrors the federal policy</p>
        </div>
      )
    }

    if (province === 'PE') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About PEI R&D tax credits</h3>
          <p>There are no provincial SRED credits awarded in PEI. PEI companies receive 
            federal SRED credits only</p>
        </div>
      )
    }

    if (province === 'YT') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Yukon R&D tax credits</h3>
          <p>The YT R&D tax credit is a 15% refundable tax credit for all corporations in YT</p>
          <p>There is no expenditure limit</p>
        </div>
      )
    }

    if (province === 'NU') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Nunavut R&D tax credits</h3>
          <p>There are no provincial SRED credits awarded in Nunavut. Nunavut
            companies receive federal SRED credits only</p>
        </div>
      )
    }

    if (province === 'NT') {
      return (
        <div className={styles.about_text}>
          <br />
          <h3>About Northwest Territories R&D tax credits</h3>
          <p>There are no provincial SRED credits awarded in NT. NT companies
            receive federal SRED credits only</p>
        </div>
      )
    }
  }

  const renderNotes = () => {
    if (fieldValues.province === 'ON') {
      return (
        <div className={styles.notes}>
          <h3>Notes to Calculator</h3>
          <p>*Enter the portion of salaries spent doing SRED work (eg 10% x total salary, etc)</p>
          <p>Refundable credits are cash.   </p>
          <p>Non-refundable credits must be used to pay down a like tax balance (eg fed to fed)</p>
          <p>Waiving ORDTC increases your federal ITCs by a percentage of the waived ORDTC amount.</p>
          <p>Waiving ORDTC is normally done to maximize cash credits for a company with no profits/tax balances foreseen.</p>
          <p>g6consulting.ca. - Greg Hills - ghills@g6consulting (416) 704-3879</p>
        </div>
      )
    }

    return (
      <div className={styles.notes}>
        <h3>Notes to Calculator</h3>
        <p>*Enter the portion of salaries spent doing SRED work (eg 10% x total salary, etc)</p>
        <p>Refundable credits are cash.</p>
        <p>Non-refundable credits must be used to pay down a like tax balance (eg fed to fed)</p>
        <p>g6consulting.ca. - Greg Hills - ghills@g6consulting (416) 704-3879</p>
      </div>
    )
  }

  const getFederalITCCredits = (data) => {
    const {
      firstQuestion,
      ownersT4Salaries,
      employeeT4Salaries,
      scrapMaterials,
      subContractors,
      cewsIRAP,
      ontarioOITCCredits,
      ontarioORDTCCredits
    } = data;
    if (data.province !== 'ON') {
      const result = (firstQuestion === "Yes" ? ((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8) - f(ontarioOITCCredits)) * 0.35 : ((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8) - f(ontarioOITCCredits)) * 0.15)
      return { ...data, federalITCCredits: result }
    }
    // (K11==="Yes"?((((K21+K23-K31)<0?0:(K21+K23-K31))+(K21+K23)*0.55)+K25+(K27*0.8)-H38)*0.35:((((K21+K23-K31)<0?0:(K21+K23-K31))+(K21+K23)*0.55)+K25+(K27*0.8)-H38)*0.15)

    const result = (firstQuestion === "Yes" ? ((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8) - f(ontarioOITCCredits) - f(ontarioORDTCCredits)) * 0.35 : ((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8) - f(ontarioOITCCredits) - f(ontarioORDTCCredits)) * 0.15)
    return { ...data, federalITCCredits: result }
  };

  const f = (value) => {
    if (value) {
      return Number(value)
    }
    return 0
  }

  const getOntarioOITCCredits = (data) => {
    const {
      firstQuestion,
      ownersT4Salaries,
      employeeT4Salaries,
      scrapMaterials,
      cewsIRAP,
      subContractors,
      priorYearExpenditures,
      secondYearExpenditures,
      province
    } = data;

    let currentCoefficient = province ? provinceOptions.find(i => i.value === province) : 0;
    if (currentCoefficient) {
      currentCoefficient = currentCoefficient.coefficient;
    }

    if (province === 'ON') {
      const result = (((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8)) * f(currentCoefficient))
      return { ...data, ontarioOITCCredits: result }
    }

    if (province === 'AB') {
      const x = 1.55 * f(ownersT4Salaries) + 1.55 * f(employeeT4Salaries) + f(scrapMaterials) + 0.8 * f(subContractors) - f(cewsIRAP);
      const result = (f(x) - (f(priorYearExpenditures) + f(secondYearExpenditures)) / 2) > 0 ? 0.08 * f(x) + 0.12 * (f(x) - (f(priorYearExpenditures) + f(secondYearExpenditures)) / 2) : 0;

      return { ...data, ontarioOITCCredits: result }
    }

    if (firstQuestion === 'No') {
      const result = ((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55 + f(scrapMaterials) + f(subContractors) * 0.8) * f(currentCoefficient))
      return { ...data, ontarioOITCCredits: result }
    }

    const result = (firstQuestion === "Yes" ? ((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8)) * f(currentCoefficient) : 0)

    return { ...data, ontarioOITCCredits: result }
  };

  const getOntarioORDTCCredits = (data) => {
    const {
      secondQuestion,
      ownersT4Salaries,
      employeeT4Salaries,
      scrapMaterials,
      subContractors,
      cewsIRAP,
      ontarioOITCCredits
    } = data;

    const result = (secondQuestion === "No" ? (((((f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP)) < 0 ? 0 : (f(ownersT4Salaries) + f(employeeT4Salaries) - f(cewsIRAP))) + (f(ownersT4Salaries) + f(employeeT4Salaries)) * 0.55) + f(scrapMaterials) + (f(subContractors) * 0.8) - f(ontarioOITCCredits))) * 0.035 : 0);

    return { ...data, ontarioORDTCCredits: result }
  }
  const round = (value) => {
    return Math.round(value);
  }

  const getTotal = (data) => {
    const {
      federalITCCredits,
      ontarioOITCCredits,
      ontarioORDTCCredits
    } = data;
    const totalResult = data.province === 'ON' ? {
      federalITCCredits: round(f(federalITCCredits)),
      ontarioOITCCredits: round(f(ontarioOITCCredits)),
      ontarioORDTCCredits: round(f(ontarioORDTCCredits)),
      total: round(f(federalITCCredits)) + round(f(ontarioOITCCredits)) + round(f(ontarioORDTCCredits))
    } : {
      federalITCCredits: round(f(federalITCCredits)),
      ontarioOITCCredits: round(f(ontarioOITCCredits)),
      total: round(f(federalITCCredits)) + round(f(ontarioOITCCredits))
    };

    return { ...data, ...totalResult };
  }

  const onSelect = (value, field) => {
    const currentData = {
      ...fieldValues,
      [field]: value,
      federalITCCredits: '',
      ontarioOITCCredits: '',
      ontarioORDTCCredits: '',
      total: ''
    };
    const updatedFields = getCalculatedValues(currentData);
    setValues({ ...updatedFields })
  }

  const getCalculatedValues = (data) => {
    const resultOntarioOITCCredits = getOntarioOITCCredits(data);
    const resultOntarioORDTCCredits = getOntarioORDTCCredits(resultOntarioOITCCredits);
    const resultFederalITCCredits = getFederalITCCredits(resultOntarioORDTCCredits);
    const totalResult = getTotal(resultFederalITCCredits);

    return totalResult;
  }

  document.addEventListener('keydown', logKey);

  function logKey(e) {
    if (['ArrowUp', 'ArrowDown'].includes(e.code)) {
      e.preventDefault()
    }
  }

  const onFieldChange = (event) => {
    const { value, name } = event.target;
    const formattedValue = value.includes('$') ? Number(value.replace('$', '')) : Number(value);
    if (isNaN(formattedValue)) {
      return null
    }
    if (formattedValue >= 0) {
      const currentData = {
        ...fieldValues,
        [name]: formattedValue,
        federalITCCredits: '',
        ontarioOITCCredits: '',
        ontarioORDTCCredits: '',
        total: ''
      };
      const updatedFields = getCalculatedValues(currentData);
      setValues({ ...updatedFields });
    }
  }

  const getTitleRelatedToProvince = () => {
    const { province } = fieldValues;

    if (province === 'AB') {
      return 'AB IEG'
    }

    if (province === 'ON') {
      return 'ON OITC'
    }

    if (province === 'BC') {
      return 'BC SRED TC'
    }

    return `${province} R&D TC`
  }

  const getOntarioOITCCreditsLabel = () => {
    const creditsAreEntered = fieldValues.ontarioOITCCredits && fieldValues.ontarioOITCCredits !== 0;

    if (['YT', 'ON', 'AB'].includes(fieldValues.province) && creditsAreEntered) {
      return <span className={styles.additionsl_label}>Refundable</span>
    }

    if (fieldValues.province === 'MB' && creditsAreEntered) {
      return <span className={styles.additionsl_label}>50% Refundable</span>
    }

    if (fieldValues.firstQuestion === 'No') {
      if (['SK', 'BC'].includes(fieldValues.province) && creditsAreEntered) {
        return <span className={styles.additionsl_label}>Non-Refundable</span>
      }

      if (['NS', 'NB', 'NL'].includes(fieldValues.province) && creditsAreEntered) {
        return <span className={styles.additionsl_label}>Refundable</span>
      }
    }

    if (fieldValues.ontarioOITCCredits && fieldValues.ontarioOITCCredits !== 0) {
      return <span className={styles.additionsl_label}>{fieldValues.firstQuestion === "Yes" ? 'Refundable' : 'Non-refundable'}</span>
    }
    
    return null;
  }
  
  const getFederalITCCreditsLabel = () => {
    if (fieldValues.federalITCCredits && fieldValues.federalITCCredits !== 0) {
        return (
          <span className={styles.additionsl_label}>{fieldValues.firstQuestion === "Yes" ? 'Refundable' : ['AB - FYEs after 1/1/20', 'BC'].includes(fieldValues.province) ? 'Refundable' : 'Non-refundable'}</span>
        )
    }
    return null;
  }

  return (
    <div className={styles.general_container}>
      <div className={styles.calculator_wrapper}>
        <div className={styles.titles_section}>
          <img className={styles.logo} src="/g6_consulting_logo.png" alt="g6_consulting_logo" />
          <h1>G6 Consulting SRED Credits Calculator</h1>
          <h3>Valid for 2020+ Fiscal Years</h3>
        </div>

        <div className={styles.first_section_wrapper}>

          <div className={styles.question}>
            <label>Are you a Canadian Controlled Private Corporation (CCPC)?  Yes or No</label>
            <SelectController
              name="firstQuestion"
              handleChange={onSelect}
              value={fieldValues.firstQuestion}
              options={questionOptions}
            />
          </div>


          <div className={styles.question}>
            <label>Your Company HQ is in Which Province?</label>
            <SelectController
              name="province"
              handleChange={onSelect}
              value={fieldValues.province}
              options={provinceOptions}
              placeholder='Select province'
            />
          </div>

          {fieldValues.province === 'AB' ? <div className={styles.question_container}>
            <div className={styles.question_in_container}>
              <label>Prior year AB SRED expenditures</label>
              <InputController
                name="priorYearExpenditures"
                onChange={onFieldChange}
                value={fieldValues.priorYearExpenditures}
              />
            </div>
            <div className={styles.question_in_container}>
              <label>Second prior year AB SRED expenditures</label>
              <InputController
                name="secondYearExpenditures"
                onChange={onFieldChange}
                value={fieldValues.secondYearExpenditures}
              />
            </div>
          </div> : null}

          {fieldValues.province === 'ON' ? <div className={styles.question}>
            <label>Would you like to waive the ORDTC non-refundable credit?  Yes or No</label>
            <SelectController
              name="secondQuestion"
              handleChange={onSelect}
              value={fieldValues.secondQuestion}
              options={fieldValues.province === 'ON' ? questionOptions : { value: 'Yes', label: 'Yes' }}
            />
          </div> : null}

        </div>

        <div className={styles.general_section_wrapper}>
          <h3>Enter Eligible SRED expenses:</h3>

          <div className={styles.question}>
            <label>Owner's T4 salaries (maximum 75% of an owner's salary, no bonuses)</label>
            <div className={styles.calculation_rapper}>
              <InputController
                name="ownersT4Salaries"
                type="number"
                value={fieldValues.ownersT4Salaries}
                onChange={onFieldChange}
              />
            </div>
          </div>

          <div className={styles.question}>
            <label>Employee T4 salaries</label>
            <div className={styles.calculation_rapper}>
              <InputController
                name="employeeT4Salaries"
                type="number"
                value={fieldValues.employeeT4Salaries}
                onChange={onFieldChange}
              />
            </div>
          </div>

          <div className={styles.question}>
            <label>Scrap materials and prototype parts</label>
            <div className={styles.calculation_rapper}>
              <InputController
                name="scrapMaterials"
                type="number"
                value={fieldValues.scrapMaterials}
                onChange={onFieldChange}
              />
              {/* {fieldValues.scrapMaterials ? <span>$</span> : null} */}
            </div>
          </div>

          <div className={styles.question}>
            <label>Subcontractors (must have valid SIN or HST number)</label>
            <div className={styles.calculation_rapper}>
              <InputController
                name="subContractors"
                type="number"
                value={fieldValues.subContractors}
                onChange={onFieldChange}
              />
              {/* {fieldValues.subContractors ? <span>$</span> : null} */}
            </div>
          </div>

          <h3>Enter deductible amounts received:</h3>

          <div className={styles.question}>
            <label>CEWS, IRAP and any other government subsidies received in the year</label>
            <div className={styles.calculation_rapper}>
              <InputController
                name="cewsIRAP"
                type="number"
                value={fieldValues.cewsIRAP}
                onChange={onFieldChange}
              />
            </div>
          </div>

        </div>

        <div className={styles.total_resutls}>
          <h3>SRED Credits</h3>

          <div className={styles.results_rows}>

            <div className={styles.row}>
              <label>Federal ITC</label>
              <InputController
                disabled={true}
                name="federalITCCredits"
                type="number"
                value={fieldValues.federalITCCredits}
              />
              {getFederalITCCreditsLabel()}
            </div>

            {['PE', 'NU', 'NT'].includes(fieldValues.province) === false ? 
              <div className={styles.row}>
                <label>{getTitleRelatedToProvince()}</label>
                <InputController
                  disabled={true}
                  name="ontarioOITCCredits"
                  type="number"
                  value={fieldValues.ontarioOITCCredits}
                />
                {getOntarioOITCCreditsLabel()}
              </div> : null}
            

            {fieldValues.province === 'ON' ? <div className={styles.row}>
              <label>ON ORDTC</label>
              <InputController
                disabled={true}
                type="number"
                name="ontarioORDTCCredits"
                value={fieldValues.ontarioORDTCCredits}
              />
              {fieldValues.ontarioORDTCCredits && fieldValues.ontarioORDTCCredits !== 0 ? <span className={styles.additionsl_label}>Non-refundable</span> : null}
            </div> : null}

            <div className={styles.row}>
              <label>Total SR&ED credits</label>
              <InputController
                disabled={true}
                name="total"
                type="number"
                value={fieldValues.total}
              />
            </div>

          </div>
        </div>

        {renderAboutFederalSREDCredits()}
        {renderAboutProvinceSREDCredits()}
      </div>

      {renderNotes()}
    </div>
  )
}
export default Calculator;

