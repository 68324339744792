import styles from './Common.module.scss';

const InputController = (props) => {
  const { value, name, onChange, disabled } = props;
  const formattedStyle = disabled ? `${styles.input_disabled} ${styles.input_field}` : styles.input_field;

  const setSelectionAfterDollarSign = (ev) => {
    const inputField = ev.currentTarget;

    if (inputField.selectionStart === 0) {
      inputField.selectionStart = (value + '').length + 2;
      inputField.focus()
    }
  }

  return (
    <div style={{position: 'relative'}}>
      {disabled ? <div className={styles.disabled_block} /> : null}
      <input 
      style={{textAlign: 'right'}}
        name={name} 
        className={formattedStyle} 
        value={!disabled ? value === 0 ? '$ ' : `$ ${value}` : `$ ${value}` } 
        onChange={!disabled ? e => onChange(e) : () => null} 
        type={'text'}
        placeholder=""
        onClick={setSelectionAfterDollarSign}
      />
    </div>
  )
}

export default InputController;
